import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../../layouts/index'
import styles from './slack.module.css'

import slackLogo from '../../../assets/integrations/slack.png'
import logo from '../../../assets/icon.png'

const SlackPage = () => (
  <Layout>
    <Helmet title={'Connect Slack to Suggested'}></Helmet>
    <div className="page">
      <div className={styles.hero}>
        <div className={styles.left}>
          <div className={styles.title}>
            Get updated in realtime when your users give you feedback.
          </div>
          <div className={styles.cta}>
            <div className={styles.slackButton}>
              <a href="https://slack.com/oauth/authorize?client_id=117230487234.478300579122&scope=incoming-webhook,team:read">
                <img alt="Slack logo" src={slackLogo} />
                <span>
                  Connect your <b>Slack</b> workspace
                </span>
              </a>
            </div>
          </div>
          <div className={styles.help}>
            You'll need a Suggested account first. Get started with a{' '}
            <a href="https://app.suggested.co/register">free plan</a>
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.inner}>
            <img alt="Slack logo" src={slackLogo} />
            <span className={styles.plus}>+</span>
            <img alt="Suggested logo" src={logo} />
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default SlackPage
